<template>
  <div class="flex-col page">
    <!-- W -->
    <div id="page" class="flex-col justify-start self-stretch section">
      <div class="tabs">
        <img
          class="image pos"
          src="../../img/17f49ecef80e4c6248070c401a94c032.png"
        />
        <span
          class="font_2 pos_6"
          :class="tabsIndex == 0 ? 'font_2_choose' : ''"
          @click="tabsClick(0)"
          @mouseenter="severShow = false"
          @mouseleave="severShow = false"
          >首页</span
        >
        <span
          class="font_2 pos_7"
          :class="tabsIndex == 1 ? 'font_2_choose' : ''"
          @click="tabsClick(1)"
          @mouseenter="severShow = false"
          @mouseleave="severShow = false"
          >服务范围</span
        >
        <span
          class="font_2 pos_8"
          :class="tabsIndex == 2 ? 'font_2_choose ' : ''"
          @click="tabsClick(2)"
          @mouseenter="severShow = false"
          @mouseleave="severShow = false"
          >品牌故事</span
        >
        <span
          class="font_2 pos_9"
          :class="tabsIndex == 3 ? 'font_2_choose' : ''"
          @click="tabsClick(3)"
          @mouseenter="severShow = false"
          @mouseleave="severShow = false"
          >企业团队</span
        >
        <span
          class="font_2 pos_10"
          :class="tabsIndex == 4 ? 'font_2_choose' : ''"
          @click="tabsClick(4)"
          @mouseenter="severShow = false"
          @mouseleave="severShow = false"
          >服务保障</span
        >
        <span
          class="font_2 pos_10_2"
          :class="tabsIndex == 5 ? 'font_2_choose' : ''"
          @click="tabsClick(5)"
          @mouseenter="severShow = false"
          @mouseleave="severShow = false"
          >联系我们</span
        >
      </div>
      <!--  -->
      <el-carousel :interval="5000" arrow="always" height="100vh" id="page">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.url" alt="Slide Image" class="bannerImg" />
          <div
            class="flex-col justify-start items-center text-wrapper pos_14"
            :style="{ border: '1px solid' + item.borderTextColor }"
            @click="tabsClick(5)"
          >
            <span class="font_4 text_6" :style="{ color: item.borderTextColor }"
              >立即咨询</span
            >
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!--  -->

    <!--  -->
    <div class="flex-col self-stretch group_4">
      <!--  -->
      <div id="page_2" class="page_2">
        <div class="flex-col self-start group_2">
          <span class="self-start font_5">SERVICE</span>
          <span class="page_title">服务范围</span>
          <div
            class="flex-col justify-start items-start self-start section_5 mt-17"
          >
            <div class="shrink-0 section_4"></div>
          </div>
        </div>
        <div class="flex-row flex-wrap equal-division group_6 card" style="">
          <div class="group_2_item" v-for="(item, index) in group2List" :key="index">
            <div class="flex-row justify-between">
              <img
                class="pic_1"
                :src="item.src"
              />
              <img
                class="pic_2"
                :src="item.src_1"
              />
              <div class="num">{{ item.num }}</div>
            </div>
            <div class="pos">
              <div class="p_1">{{ item.title }}</div>
              <div class="p_2">{{ item.desc }}</div>
            </div>
          </div>
        </div>
        <img
          class="image_7"
          src="../../img/f49d2358178bd9cb41ab5cf15ddb7535.png"
        />
      </div>
      <!-- 专业铸就信任，服务温暖人心 -->
      <div id="page_3" class="page_3">
        <div class="title">专业铸就信任，服务温暖人心</div>
        <div id="page_3_1">
          <div class="card flex-row">
            <div class="grade">01.</div>
            <div class="w_555">
              <div class="p_1">数字化应用</div>
              <div class="p_2">
                为客户提供全面的互联网服务解决方案。我们提供的一站式服务涵盖了从网站设计、开发到在线营销和数据分析的全方位需求，确保客户能够轻松管理其在线业务，我们的定制服务团队专注于理解每个客户的独特需求，为他们量身打造最适合的解决方案。
              </div>
            </div>
          </div>
          <img class="pic_1" src="../../img/image/page_3_3.png" alt="">
        </div>
        <div class="bot flex-row">
          <img class="pic_2" src="../../img/image/page_3_1.png" alt="">
          <div id="page_3_2">
            <div class="card flex-row">
              <div class="grade">02.</div>
              <div class="w_555">
                <div class="p_1">美学创意体</div>
                <div class="p_2">
                  由多元化跨专业的青年新锐设计师、品牌策划师、摄影师联合合作的专注品牌设计与服务的团队。团队服务项目包括 : 品牌视觉设计、商业包装设计、印刷品设计、产品美学摄影
                </div>
              </div>
            </div>
            <img class="pic_3" src="../../img/image/page_3_2.png" alt="">
          </div>
        </div>
      </div>


      <!--  -->
      <div id="page_4" class="page_4">
        <div class="flex-col self-start group_2">
          <span class="self-start font_5">ENTERPRISE TEAM</span>
          <span class="page_title">企业团队</span>
          <div
            class="flex-col justify-start items-start self-start section_5 mt-17"
          >
            <div class="shrink-0 section_4"></div>
          </div>
        </div>
        <div class="picList">
          <div class="flex-row">
            <div :class="'arr1 ' + (picHoverIndex==index?'on':'')" v-for="(item, index) in page4List_1" :key="index" @mouseenter="picListHover(index)">
              <img :src="item" alt="" >
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-row arr2" v-for="(item, index) in page4List_2" :key="index">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div id="page_5" class="page_5">
        <div class="flex-col self-start group_2">
          <span class="self-start font_5">SERVICE GUARANTEE</span>
          <span class="page_title">服务保障</span>
          <div
            class="flex-col justify-start items-start self-start section_5 mt-17"
          >
            <div class="shrink-0 section_4"></div>
          </div>
        </div>
        <div class="picList flex-row">
          <div class="item flex-col items-center justify-center" v-for="(item, index) in page5List" :key="index">
            <img
              class="pic_1"
              :src="item.src"
            />
            <img
              class="pic_2"
              :src="item.src_1"
            />
            <div class="p_1">{{ item.title }}</div>
            <div class="p_2">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <!--  -->
      <div id="page_6" class="page_6">
        <div class="flex-col self-start group_2">
          <span class="self-start font_5">CONTACT US</span>
          <span class="page_title">联系我们</span>
          <div
            class="flex-col justify-start items-start self-start section_5 mt-17"
          >
            <div class="shrink-0 section_4"></div>
          </div>
        </div>

        <div class="card flex-row">
          <div class="item hover_1">
            <div class="icon">
              <img src="../../img/image/page_6_1.png" alt="">
            </div>
            <div class="p_1">电话咨询</div>
            <div class="p_2">15136339268</div>
          </div>
          <div class="item hover_2">
            <img class="qr" src="../../img/2.png" alt="">
            <div class="icon">
              <img src="../../img/image/page_6_4.png" alt="">
            </div>
            <div class="p_1">微信咨询</div>
          </div>
          <div class="item hover_3">
            <div class="icon">
              <img src="../../img/image/page_6_3.png" alt="">
            </div>
            <div class="p_1">深圳市福田区华富街道<br/>笋岗西路3072-1号</div>
          </div>
        </div>


      </div>

    </div>
    <!--  -->
    <!--  -->
    <div class="flex-col self-stretch group_47">
      <!--  -->
      <div class="footer">
        <div class="left">
          <img class="logo" src="../../img/image/logo.png"/>
          <div class="flex-row items-end">
            <img class="qr" src="../../img/2.png"/>
            <div class="info">
              <div class="item">
                <img class="icon" src="../../img/image/footer_1.png" alt="">
                联系电话：15136339268
              </div>
              <div class="item">
                <img class="icon" src="../../img/image/footer_2.png" alt="">
                公司地址： 深圳市福田区华富街道笋岗西路3072-1号
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="nav flex-col">
            <div class="item" v-for="(item, index) in navList" :key="index" @click="tabsClick(index)">
              <span>{{ item }}</span>
            </div>
          </div>
        </div>

        <img class="map" src="../../img/image/map.png" alt="">

      </div>

      <!--  -->
      <div class="flex-col justify-start items-center text-wrapper_4">
        <span class="font_19 text_83"
          >Copyright © 2024 深圳亥时科技有限公司
          版权所有 粤ICP备2024309177号-1</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tabsIndex: 0,
      bannerList: [
        {
          url: require("../../img/banner/banner_2.png"),
          borderTextColor: "#124297",
        },
        {
          url: require("../../img/banner/banner_3.png"),
          borderTextColor: "#124297",
        },
        {
          url: require("../../img/banner/banner_1.png"),
          borderTextColor: "#e8703d",
        },
      ],
      group2List: [
        {
          title: "应用系统开发",
          desc: "各类业务系统，如CRM、ERP、OA等的开发",
          src: require("../../img/image/group2_01.png"),
          src_1: require("../../img/image/group2_01_1.png"),
          num: "01"
        },
        {
          title: "移动端应用开发",
          desc: "Android、IOS应用开发、定制化终端设备开发",
          src: require("../../img/image/group2_02.png"),
          src_1: require("../../img/image/group2_02_1.png"),
          num: "02",
        },
        {
          title: "小程序定制开发",
          desc: "基于微信、钉钉、抖音平台，定制开发小程序",
          src: require("../../img/image/group2_03.png"),
          src_1: require("../../img/image/group2_03_1.png"),
          num: "03",
        },
        {
          title: "物联网应用开发",
          desc: "高效实现物联网设备、服务及应用开发",
          src: require("../../img/image/group2_04.png"),
          src_1: require("../../img/image/group2_04_1.png"),
          num: "04",
        },
        {
          title: "品牌视觉形象设计规划",
          desc: "绘制品牌的灵魂，让品牌故事在每个细节中绽放",
          src: require("../../img/image/group2_05.png"),
          src_1: require("../../img/image/group2_05_1.png"),
          num: "05",
        },
        {
          title: "商业包装设计",
          desc: "包装不仅是外衣，更是艺术的展现",
          src: require("../../img/image/group2_06.png"),
          src_1: require("../../img/image/group2_06_1.png"),
          num: "06",
        },
        {
          title: "印刷品设计",
          desc: "以匠心独运的设计理念，为每一张纸赋予意义",
          src: require("../../img/image/group2_07.png"),
          src_1: require("../../img/image/group2_07_1.png"),
          num: "07",
        },
        {
          title: "产品美学摄影",
          desc: "捕捉光影下的静谧之美，用镜头讲述产品的故事",
          src: require("../../img/image/group2_08.png"),
          src_1: require("../../img/image/group2_08_1.png"),
          num: "08",
        }

      ],
      picHoverIndex: 0,
      page4List_1: [
        require("../../img/image/page_4_1.jpg"),
        require("../../img/image/page_4_2.jpg"),
        require("../../img/image/page_4_3.jpg"),
        require("../../img/image/page_4_4.jpg"),
      ],
      page4List_2: [
        require("../../img/image/page_4_5.jpg"),
        require("../../img/image/page_4_6.jpg"),
        require("../../img/image/page_4_7.jpg"),
        require("../../img/image/page_4_8.jpg"),
      ],
      page5List: [
        {
          title: "升级服务",
          desc: "软件升级，响应升级",
          src: require("../../img/image/page_5_01.png"),
          src_1: require("../../img/image/page_5_01_1.png"),
          num: "01"
        },
        {
          title: "增值服务",
          desc: "定制开发，性能调优",
          src: require("../../img/image/page_5_02.png"),
          src_1: require("../../img/image/page_5_02_1.png"),
          num: "02",
        },
        {
          title: "专属服务",
          desc: "定期驻场，运维支持",
          src: require("../../img/image/page_5_03.png"),
          src_1: require("../../img/image/page_5_03_1.png"),
          num: "03",
        },
        {
          title: "培训服务",
          desc: "产品培训，轻松上手",
          src: require("../../img/image/page_5_04.png"),
          src_1: require("../../img/image/page_5_04_1.png"),
          num: "04",
        },
        {
          title: "咨询方案",
          desc: "业务咨询，方案落地",
          src: require("../../img/image/page_5_05.png"),
          src_1: require("../../img/image/page_5_05_1.png"),
          num: "05",
        },
        {
          title: "运维服务",
          desc: "服务专员，业务稳定",
          src: require("../../img/image/page_5_06.png"),
          src_1: require("../../img/image/page_5_06_1.png"),
          num: "06",
        },

      ],
      resultIndex: 0,
      LLEindex: 0,
      severShow: false,
      oneRect: "",
      twoRect: "",

      navList: [
        '首页',
        '服务范围',
        '品牌故事',
        '企业团队',
        '服务保障',
        '联系我们',
      ],

      sections: [],
      scrollOffset: 200,

    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initRects();
    });
    window.addEventListener("scroll", this.handleScroll, true);
    if (this.$route.query.tabsIndex) {
      this.tabsClick(this.$route.query.tabsIndex);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 初始化元素位置
    initRects() {
      this.sections = [
        { id: "page", element: document.getElementById("page") },
        { id: "page_2", element: document.getElementById("page_2") },
        { id: "page_3", element: document.getElementById("page_3") },
        { id: "page_4", element: document.getElementById("page_4") },
        { id: "page_5", element: document.getElementById("page_5") },
        { id: "page_6", element: document.getElementById("page_6") },
      ];
    },

    tabsClick(index) {
      if (index >= 0 && index < this.sections.length) {
        this.severShow = false;
        const targetSection = this.sections[index];
        if (targetSection && targetSection.element) {
          const rect = targetSection.element.getBoundingClientRect();
          const offsetTop = rect.top + window.pageYOffset - this.scrollOffset;
          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          });
          this.tabsIndex = index;
        }
      }
    },

    // 滚动事件处理，检测是否在可视范围内
    handleScroll() {
      this.sections.forEach((section, index) => {
        const rect = section.element.getBoundingClientRect();
        if (
          rect.top >= -this.scrollOffset && 
          rect.top <= window.innerHeight - this.scrollOffset
        ) {
          if (this.tabsIndex !== index) {
            this.tabsIndex = index;
            if (this.tabsIndex == 2) {
              const page3_1 = document.getElementById("page_3_1");
              const page3_2 = document.getElementById("page_3_2");
              if (page3_1) page3_1.classList.add("slide-in");
              if (page3_2) page3_2.classList.add("slide-in");
            }
          }
        }
      });
      
    },
    productTabsClick(e) {
      this.productTabs = e;
    },
    resultTabsClick(e) {
      this.resultIndex = e;
    },
    fixedClick(e) {
      document.getElementById("page").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    },
    customTabsClick(e) {
      this.customIndex = e;
    },
    customMore() {
      this.$router.push("/custom");
    },
    productMore() {
      this.$router.push("/product");
    },
    productDetail(e) {
      window.open(e, "_blank");
    },
    mouseenter(e) {
      this.LLEindex = e;
    },
    serveDetail(e, index) {
      this.serveIndex = index;
      setTimeout(() => {
        window.open(e, "_blank");
        this.severShow = false;
      }, 1000);
    },
    mouseLeave(e) {
      this.severShow = false;
      this.serveIndex = -1;
    },
    goOther(e) {
      window.open(e, "_blank");
    },

    //
    picListHover (e) {
      this.picHoverIndex= e
    }
  },
};
</script>

<style scoped lang="scss">
.mt-27 {
  margin-top: 27px;
}

.mt-63 {
  margin-top: 63px;
}

.mt-17 {
  margin-top: 17px;
}

.mt-43 {
  margin-top: 43px;
}

.ml-21 {
  margin-left: 21px;
}

.ml-3 {
  margin-left: 3px;
}

.ml-19 {
  margin-left: 19px;
}

.ml-13 {
  margin-left: 13px;
}

.mt-89 {
  margin-top: 89px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-79 {
  margin-top: 79px;
}

.ml-9 {
  margin-left: 9px;
}

.ml-35 {
  margin-left: 35px;
}

.mt-13 {
  margin-top: 13px;
}

.mt-11 {
  margin-top: 11px;
}

.ml-226 {
  margin-left: 226px;
}

.mt-29 {
  margin-top: 29px;
}

.mt-21 {
  margin-top: 21px;
}

.ml-73 {
  margin-left: 73px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-61 {
  margin-top: 61px;
}

.ml-117 {
  margin-left: 117px;
}

.ml-162 {
  margin-left: 162px;
}

.ml-106 {
  margin-left: 106px;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  position: relative;
  .bannerImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .tabs {
    height: 98px;
    background: rgba(255,255,255,0.8);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999;
  }

  .section {
    .image {
      width: 160px;
      // height: 40px;
    }

    .pos {
      position: absolute;
      left: 240px;
      top: 28px;
    }

    .image_2 {
      width: 94px;
      height: 23px;
    }

    .pos_2 {
      position: absolute;
      left: 308px;
      top: 29px;
    }

    .font_2 {
      font-size: 16px;
      font-family: PingFang;
      line-height: 16px;
      color: #000;
      cursor: pointer;
    }

    .font_2_choose {
      padding-bottom: 8px;
      border-bottom: 3px solid #0E59AB;
      color: #0E59AB;
    }

    .font_2:hover {
      color: #0E59AB;
    }

    .pos_6 {
      position: absolute;
      left: 660px;
      top: 39px;
    }

    .pos_7 {
      position: absolute;
      left: 802px;
      top: 39px;
    }

    .pos_8 {
      position: absolute;
      left: 976px;
      top: 39px;
    }

    .pos_9 {
      position: absolute;
      left: 1150px;
      top: 39px;
    }

    .pos_10 {
      position: absolute;
      left: 1324px;
      top: 39px;
    }

    .pos_10_2 {
      position: absolute;
      left: 1498px;
      top: 39px;
    }

    .text {
      line-height: 20px;
    }

    .pos_4 {
      position: absolute;
      right: 246px;
      top: 37px;
    }

    .text_3 {
      color: #ffffff;
      font-size: 14px;
      font-family: PingFang;
      line-height: 12px;
    }

    .pos_11 {
      position: absolute;
      left: 308px;
      top: 55px;
    }

    .group {
      text-align: center;
      width: 1024px;

      .font_3 {
        font-size: 72px;
        font-family: FZZDHJW;
        letter-spacing: 7px;
        line-height: 140px;
      }

      .text_4 {
        color: #ffffff;
      }

      .text_5 {
        color: #0E59AB;
        white-space: nowrap;
      }
    }

    .text-wrapper {
      padding: 14px 0;
      border-radius: 24px;
      width: 180px;

      .text_6 {
        font-weight: 700;
        line-height: 19px;
      }
    }

    .pos_14 {
      position: absolute;
      left: 50%;
      top: 75%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    .pos_14:hover {
      .font_4 {
        color: #0E59AB;
      }
    }
  }

  //
  .group_2 {
    margin-left: 240px;
    margin-top: 89px;
    width: 755px;

    .section_5 {
      background-color: #e6e6e6;
      border-radius: 1.5px;
      width: 240px;

      .section_4 {
        background-color: #0E59AB;
        width: 70px;
        height: 5px;
      }
    }

    .group_3 {
      line-height: 30px;
    }
  }

  .group_4 {
    margin-top: 53px;

    .group_5 {
      padding-top: 460px;

      .image_7 {
        width: 100%;
        height: 9.5521vw;
        margin-top: 60px;
      }

      .image-wrapper {
        padding: 409px 0 51px;
        background-color: #0E59AB;
        border-radius: 5px;
        width: 320px;

        .image_8 {
          margin-left: 41px;
          width: 48px;
          height: 10px;
        }
      }

      .pos_17 {
        position: absolute;
        right: 240px;
        top: 0;
      }

      .equal-division {
        position: absolute;
        left: 240px;
        top: 0px;
        // transform: translateX(-51%);

        .group_7:hover {
          background: #0E59AB;

          .font_7 {
            color: #fff;
          }

          .font_8 {
            color: #fff;
          }
        }

        .group_7 {
          width: 345px;
          height: 240px;
          background: #f6f6f6;

          .image_6 {
            width: 80px;
            height: 80px;
          }

          .group_11 {
            line-height: 36px;
          }

          .group_14 {
            line-height: 26px;
          }

          .group_12 {
            line-height: 36px;
            width: 98px;
          }

          .group_15 {
            line-height: 26px;
          }

          .font_8 {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            line-height: 26px;
            color: #666666;
          }

          .group_10 {
            width: 100px;

            .group_13 {
              margin-left: 2px;
              line-height: 36px;
            }
          }

          .group_16 {
            margin-left: 2px;
            line-height: 26px;

            .font_9 {
              font-size: 16px;
              font-family: MicrosoftYaHei;
              line-height: 26px;
              color: #ffffff;
            }
          }
        }

        .equal-division-item {
          padding: 59px 39px 24px 41px;
        }

        .group_8 {
          padding: 59px 39px 24px 41px;
          width: 347px;
        }
      }
    }

    .section_6 {
      background-image: url("../../img/f864fd365f6064e42285bbd76f86fded.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .group_17 {
        padding-left: 56px;
        padding-right: 57px;

        .group_18 {
          width: 741px;

          .section_8 {
            margin-left: 3px;
            background-color: #ffffff80;
            border-radius: 1.5px;
            width: 240px;

            .section_7 {
              background-color: #ffffff;
              width: 70px;
              height: 5px;
            }
          }

          .group_19 {
            margin-left: 3px;
            line-height: 30px;
          }
        }

        .group_20 {
          margin-top: 107px;
          cursor: pointer;
        }
      }

      .group_21 {
        .group_22:hover {
          .text_8 {
            color: #0E59AB;
          }
        }

        .text_8_choose {
          color: #0E59AB;
        }

        .group_22 {
          padding: 12px 14px 0;
          cursor: pointer;

          .image_10 {
            width: 28px;
            height: 32px;
          }

          .text_7 {
            color: #2a7ee7;
            line-height: 15px;
          }

          .text_8 {
            line-height: 15px;
          }

          .image_11 {
            width: 30px;
            height: 32px;
          }

          .text_9 {
            line-height: 15px;
          }

          .pos_18 {
            position: absolute;
            left: 31px;
            bottom: 0;
          }

          .text_10 {
            line-height: 15px;
          }

          .text_11 {
            line-height: 15px;
          }
        }
      }

      .equal-division-item_2 {
        padding-bottom: 10px;
      }
    }

    .group_23 {
      margin-top: -101px;
      padding: 0 239px;

      .section_11 {
        background-color: #ffffff;
        box-shadow: 0px 9px 16px #3838381a;

        .section_12 {
          padding: 64px 63px 63px 57px;
          flex: 1 1 720px;
          background-color: #ffffff;

          .text_12 {
            color: #0E59AB;
            line-height: 24px;
            letter-spacing: 2.5px;
          }

          .text_13 {
            margin-top: 23px;
            line-height: 24px;
          }

          .text_14 {
            margin-top: 40px;
            color: #0E59AB;
            font-size: 20px;
            font-family: MicrosoftYaHei;
            font-weight: 700;
            line-height: 19px;
          }

          .section_17 {
            margin-top: 29px;

            .text_15 {
              margin-left: 13px;
            }
          }

          .image_14 {
            width: 20px;
            height: 17px;
          }

          .group_27 {
            margin-top: 32px;

            .button {
              padding: 12px 0;
              background-color: #0E59AB;
              width: 143px;
              height: 41px;
              cursor: pointer;
            }
          }
        }

        .section_13 {
          flex: 1 1 720px;
          background-image: url("https://ide.code.fun/api/image?token=6572b4a528456c001168e337&name=70885231175d1e6d2d210cb8baf64e61.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .section_15_1 {
            width: 200px;
            height: 200px;
            bottom: 150px;
            position: absolute;
            right: 35px;
            bottom: 150px;
          }

          .section_14 {
            padding: 98px 0 74px 40px;
            background-color: #ffffffdb;

            .image-wrapper_2 {
              padding: 16px 0 96px;
              background-image: url("../../img/9f756189862db795291b8bd16f5a489f.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 399px;
              height: 320px;

              .image_12 {
                width: 367px;
                height: 208px;
              }
            }

            .section_15 {
              // width: 144px;
              height: 312px;
            }

            .view {
              margin-left: 28px;
            }

            .pos_19 {
              position: absolute;
              right: 35px;
              bottom: 84px;
            }
          }
        }
      }

      .group_24 {
        position: fixed;
        top: 550px;
        right: 0px;
        z-index: 99;

        .ewm {
          width: 160px;
          height: 160px;
          position: fixed;
          top: 420px;
          right: 80px;
          z-index: 99;
          background: #ccc;
          padding: 20px;
          text-align: center;
          display: none;

          img {
            width: 120px;
            height: 120px;
          }
        }

        .weixinCode:hover {
          .ewm {
            display: block;
          }
        }

        .section_16 {
          padding: 13px 6px 13px 14px;
          background-color: #0E59AB;
          border-radius: 5px 0px 0px 5px;
        }

        .phoneShow {
          display: none;
        }

        .section_16:hover {
          .phoneShow {
            display: block;
          }
        }

        .image_15 {
          border-radius: 5px 0px 0px 5px;
          width: 50px;
          height: 50px;
        }

        .pos_20 {
          position: absolute;
          right: 0;
          top: 54px;
        }
      }

      .text_19 {
        margin-top: 80px;
      }

      .image_17 {
        margin-top: 17px;
      }

      .group_28 {
        margin-top: 36px;
        cursor: pointer;

        .button_3 {
          padding: 6px 0;
          background-color: #0E59AB;
          border-radius: 14px;
          width: 108px;
          height: 28px;
        }

        .text-wrapper_2:hover {
          .font_13 {
            color: #0E59AB;
          }
        }

        .text-wrapper_2 {
          padding: 6px 0;
          background-color: #ffffff;
          border-radius: 14px;
          width: 108px;
          height: 28px;
          border-left: solid 1px #999999;
          border-right: solid 1px #999999;
          border-top: solid 1px #999999;
          border-bottom: solid 1px #999999;

          .text_20 {
            line-height: 15px;
          }
        }
      }

      .grid {
        margin-top: 26px;
        // width: 1450.5px;
        height: 856px;
        display: grid;
        grid-template-rows: repeat(2, minmax(0, 1fr));
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 0;
        column-gap: 12.5px;

        .grid-item {
          padding: 50px 0 10px;
          width: 466px;

          .section_18 {
            margin-right: 15px;
            padding: 245px 16px 0;
            background-color: #ffffff;
            box-shadow: 0px 9px 16px #3838381a;
            width: 466px;
            border-left: solid 1px #dedede;
            border-right: solid 1px #dedede;
            border-top: solid 1px #dedede;
            border-bottom: solid 1px #dedede;

            .image_20 {
              margin-right: 5px;
            }

            .text_21 {
              margin-top: 15px;
            }
          }

          .image_18 {
            width: 466px;
            height: 263px;
          }

          .pos_21 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .section_19 {
            margin-right: 10px;
            padding: 245px 16px 0;
            background-color: #ffffff;
            width: 466px;
            border-left: solid 1px #dedede;
            border-right: solid 1px #dedede;
            border-top: solid 1px #dedede;
            border-bottom: solid 1px #dedede;

            .image_21 {
              margin-right: 4px;
              width: 47px;
              height: 18px;
            }

            .text_22 {
              margin-top: 16px;
              line-height: 15px;
            }

            .group_30 {
              padding: 0 2px;

              .image_22 {
                margin-right: 3px;
              }
            }

            .text_23 {
              margin-top: 15px;
            }

            .image_26 {
              margin-right: 4px;
              width: 21px;
              height: 18px;
            }

            .text_26 {
              margin-top: 16px;
              line-height: 15px;
            }

            .font_20 {
              font-size: 18px;
              font-family: MicrosoftYaHei;
              line-height: 16px;
              color: #333333;
            }

            .text_24 {
              line-height: 17px;
            }

            .image_27 {
              margin-right: 4px;
            }

            .text_27 {
              margin-top: 16px;
              line-height: 15px;
            }

            .text_25 {
              line-height: 17px;
            }

            .image_28 {
              margin-right: 5px;
            }

            .text_28 {
              margin-top: 16px;
              line-height: 15px;
            }
          }

          .group_31 {
            margin-top: 21px;
            padding: 16px 0;
            border-top: solid 1px #dedede;
            cursor: pointer;

            .image_23 {
              margin-right: 4px;
            }

            .image_24 {
              margin-right: 4px;
            }

            .image_25 {
              margin-right: 5px;
            }

            .image_29 {
              margin-right: 4px;
            }

            .image_30 {
              margin-right: 4px;
            }

            .image_31 {
              margin-right: 5px;
            }
          }

          .pos_22 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .image_19 {
            height: 18px;
          }

          .pos_23 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .pos_24 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .pos_25 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }

          .pos_26 {
            position: absolute;
            left: 0;
            right: 9.5px;
            top: 10px;
          }
        }
      }

      .font_15 {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        line-height: 16px;
        color: #666666;
      }
    }

    .font_7 {
      font-size: 24px;
      font-family: MicrosoftYaHei;
      line-height: 36px;
      font-weight: 700;
      color: #333333;
    }
  }

  .font {
    font-size: 24px;
    font-family: MicrosoftYaHei;
    line-height: 36px;
    font-weight: 700;
    color: #ffffff;
  }

  .font_5 {
    font-size: 60px;
    font-family: CgFuturaMaxiBd, CgFuturaMaxiBd-500;
    font-weight: 500;
    color: #0e59ab;
    line-height: 60px;
  }

  .font_4 {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    line-height: 18px;
    color: #333333;
  }

  .group_32 {
    margin-top: 60px;
    height: 880px;

    .section_20 {
      padding: 80px 161px 46px 239px;
      background-image: url("../../img/7be7012a0cb102bd771a378c0b3bdfe3.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .font {
        font-size: 42px;
        font-family: Source Han Sans CN;
        line-height: 40px;
        color: #ffffff;
      }

      .font_2 {
        font-size: 16px;
        font-family: PingFang SC;
        line-height: 16px;
        color: #ffffff;
      }

      .text_2 {
        margin-top: 28px;
        line-height: 17px;
      }

      .group {
        margin-top: 40px;

        .group_3 {
          margin-top: 10px;
          padding: 0 73px;
          border-left: 1px solid #fff;
          margin-left: 80px;

          .font_3 {
            font-size: 30px;
            font-family: PingFang SC;
            line-height: 30px;
            color: #ffffff;
          }

          .text_3 {
            line-height: 17px;
          }

          .group_4 {
            padding: 0 4px;

            .group_7 {
              width: 189px;

              .image_2 {
                width: 54px;
                height: 46px;
              }

              .text_5 {
                margin-top: 29px;
              }

              .group_9 {
                margin-top: 18px;
                line-height: 20px;
                text-align: center;
              }
            }

            .view {
              margin-top: 4px;
            }

            .font_5 {
              font-size: 16px;
              font-family: PingFang SC;
              line-height: 16px;
              font-weight: 500;
              color: #ffffff;
            }

            .font_6 {
              font-size: 14px;
              font-family: PingFang SC;
              line-height: 20px;
              color: #ffffff;
            }

            .font_7 {
              font-size: 14px;
              font-family: PingFang SC;
              line-height: 24px;
              color: #ffffff;
            }

            .image {
              width: 52px;
              height: 52px;
            }
          }
        }
      }

      .text-wrapper {
        padding: 17px 0;
        width: 200px;
        cursor: pointer;
      }

      .text-wrapper:hover {
        .font_4 {
          color: #0E59AB;
        }
      }

      .text-wrapper_choose {
        padding: 17px 0;
        background-color: #0E59AB;
        width: 200px;
      }

      .font_4 {
        font-size: 20px;
        font-family: PingFang SC;
        line-height: 20px;
        color: #ffffff;
      }

      .text_4 {
        margin-top: 71px;
      }

      .text_9 {
        margin-top: 119px;
      }
    }
  }

  .font_10 {
    font-size: 48px;
    font-family: Source Han Sans CN;
    line-height: 45px;
    color: #ffffff;
  }

  .image_16 {
    border-radius: 1.5px;
    width: 196px;
    height: 3px;
  }

  .font_17 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;
  }
  .font_17:hover {
    color: #0E59AB;
  }

  .font_17_1 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: #ffffff;
  }

  .font_12 {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 12px;
    font-weight: 700;
    color: #ffffff;
  }

  .image_9 {
    width: 24px;
    height: 10px;
  }

  .font_14 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 16px;
    color: #ffffff;
    border-bottom: 1px solid #fff;
    padding-bottom: 15px;
  }

  .font_13 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 16px;
    color: #333333;
  }

  .font_18 {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 12px;
    font-weight: 700;
    color: #999999;
  }

  .font_6 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 30px;
    color: #666666;
  }

  .font_11 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 30px;
    color: #ffffff;
  }

  .font_16 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 12px;
    color: #ffffff;
  }

  .group_47 {
    margin-top: 70px;

    .group_50 {
      margin-top: -277px;
      padding-top: 350px;

      .section_26 {
        background-image: url("../../img/b0629d124972709aa3a15de27bc7cc86.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .section_27 {
          padding: 279px 240px 143px;
          background-color: #161515e0;

          .image_48 {
            margin-top: 16px;
          }

          .text_59 {
            margin-top: 26px;
            line-height: 15px;
          }

          .ewmCode {
            width: 130px;
            height: 130px;
            position: absolute;
            top: 5px;
            left: 35%;
            display: none;
          }

          .equal-division-item_4 {
            flex: 1 1 230px;

            .image_49 {
              width: 32px;
              height: 32px;
            }

            .font_34 {
              font-size: 16px;
              font-family: Source Han Sans CN;
              letter-spacing: 1.5px;
              line-height: 16px;
              color: #ffffff;
              cursor: pointer;
              text-align: center;
            }

            .font_34_1 {
              font-size: 16px;
              font-family: Source Han Sans CN;
              letter-spacing: 1.5px;
              line-height: 16px;
              color: #ffffff;
              text-align: center;
            }

            .text_62 {
              line-height: 12px;
              display: none;
            }

            .text_61 {
              line-height: 17px;
            }
          }

          .section_28 {
            padding: 34px 0;
            background-color: #464646;
            height: 140px;
          }

          .section_28:hover {
            background-color: #0E59AB;

            .text_62 {
              display: block;
            }

            .text_62_1 {
              display: none;
            }
          }

          .text_62_1 {
            display: block;
          }

          .section_29 {
            padding: 36px 0;
            background-color: #464646;
            height: 140px;
          }

          .equal-division-item_5 {
            padding: 34px 0 37px;
            background-color: #464646;
            height: 140px;
          }

          .equal-division-item_5:hover {
            background-color: #0E59AB;

            .codeShow {
              display: none;
            }

            .ewmCode {
              display: block;
            }
          }

          .section_30 {
            padding: 34px 24px 21px 30px;
            background-color: #464646;
            height: 140px;
          }
        }
      }

      .section_25 {
        padding: 52px 240px 77px;
        background-color: #ffffff;

        .image_44 {
          margin-top: 17px;
        }

        .text_51 {
          margin-top: 26px;
          line-height: 15px;
        }

        .image_47 {
          width: 54px;
          height: 54px;
        }

        .font_31 {
          font-size: 20px;
          font-family: MicrosoftYaHei;
          line-height: 18px;
          color: #333333;
        }

        .text_52 {
          margin-top: 29px;
          line-height: 19px;
          color: #333333;
        }

        .group_55 {
          margin-top: 18px;
          line-height: 28px;
          text-align: center;

          .font_33 {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            line-height: 28px;
            color: #333333;
          }
        }

        .group_51 {
          margin-right: 45px;

          .text_53 {
            margin-top: 29px;
            line-height: 19px;
          }

          .group_56 {
            margin-top: 18px;
            line-height: 28px;
            text-align: center;
          }
        }

        .group_52 {
          margin-top: 167px;

          .text_54 {
            margin-top: 29px;
            line-height: 19px;
          }

          .group_57 {
            margin-top: 18px;
            line-height: 28px;
            text-align: center;
          }

          .group_53 {
            margin-right: 56px;

            .image_45 {
              width: 61px;
              height: 56px;
            }

            .text_55 {
              margin-top: 29px;
            }

            .group_58 {
              margin-top: 17px;
              line-height: 28px;
              text-align: center;
            }
          }

          .text_56 {
            margin-top: 29px;
          }

          .group_59 {
            margin-top: 17px;
            line-height: 28px;
            text-align: center;
          }
        }

        // .group_54 {
        //   margin-top: 167px;

        //   .image_46 {
        //     width: 66px;
        //     height: 56px;
        //   }

        //   .text_57 {
        //     margin-top: 29px;
        //   }

        //   .text_58 {
        //     margin-top: 24px;
        //   }

        //   .text_58:hover {
        //     color: #0E59AB;
        //   }
        // }

        .font_32 {
          font-size: 20px;
          font-family: MicrosoftYaHei;
          line-height: 20px;
          color: #333333;
        }
      }

      .pos_30 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
      }
    }

    .section_31 {
      padding: 58px 240px 32px;
      background-color: #222222;

      .group_62 {
        width: 274px;

        .image_50 {
          width: 55px;
          height: 55px;
        }

        .text_69 {
          margin-top: 14px;
          font-weight: 700;
          line-height: 21px;
        }

        .text_73 {
          margin-top: 18px;
          color: #ffffff;
          font-size: 30px;
          font-family: MicrosoftYaHei;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 3px;
          white-space: nowrap;
        }

        .group_66 {
          margin-top: 36px;
          line-height: 20px;

          .text_77 {
            line-height: 20px;
          }

          .text_82 {
            margin-left: 43px;
            line-height: 24px;
          }
        }
      }

      .section_32 {
        background-color: #ffffff1f;
        width: 1px;
        height: 200px;
      }

      .font_35 {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        letter-spacing: 1.5px;
        line-height: 16px;
        font-weight: 700;
        color: #ffffff;
      }

      .text_66 {
        margin-top: 32px;
      }

      .text_70 {
        margin-top: 23px;
      }

      .text_74 {
        margin-top: 22px;
      }

      .text_80 {
        margin-top: 23px;
      }

      .text_64 {
        line-height: 17px;
      }

      .text_67 {
        margin-top: 48px;
        line-height: 15px;
      }

      .text_71 {
        margin-top: 23px;
      }

      .text_75 {
        margin-top: 23px;
        line-height: 15px;
      }

      .text_81 {
        margin-top: 24px;
        line-height: 15px;
      }

      .group_63 {
        margin-top: 15px;

        .text_65 {
          line-height: 17px;
        }

        .text_68 {
          margin-top: 33px;
          line-height: 15px;
        }

        .text_72 {
          margin-top: 24px;
          line-height: 15px;
        }

        .text_76 {
          margin-top: 23px;
          line-height: 15px;
        }
      }

      .group_64 {
        width: 130px;

        .group_65 {
          opacity: 0.4;
          height: 130px;
          border-left: dashed 1px #ffffff;
          border-right: dashed 1px #ffffff;
          border-top: dashed 1px #ffffff;
          border-bottom: dashed 1px #ffffff;
        }

        .image_52 {
          width: 106px;
          height: 106px;
        }

        .pos_33 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .image_51 {
          width: 130px;
          height: 130px;
        }

        .pos_31 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .image-wrapper_3 {
          padding: 41px 0;
          background-image: url("../../img/4b64faa5aab9acfe49510fdf544c4ae3.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 106px;
        }

        .pos_34 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .pos_32 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      .group_67 {
        padding: 0 20px;

        .font_36 {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          letter-spacing: 1.5px;
          line-height: 16px;
          color: #dfdfdf;
        }

        .text_78 {
          line-height: 15px;
        }

        .text_79 {
          line-height: 15px;
        }
      }
    }

    .text-wrapper_4 {
      padding: 24px 0;
      background-color: #1b1b1b;

      .text_83 {
        line-height: 17px;
      }
    }
  }

  .font_24 {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    letter-spacing: 2px;
    line-height: 18px;
    color: #ffffff;
  }

  .image_35 {
    width: 56px;
    height: 56px;
  }

  .font_28 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 28px;
    color: #333333;
  }

  .font_21 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: #0E59AB;
  }

  .font_22 {
    font-size: 20px;
    font-family: MicrosoftYaHei;
    letter-spacing: 2px;
    line-height: 20px;
    color: #ffffff;
  }

  .image_13 {
    width: 24px;
    height: 24px;
  }

  .font_19 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 16px;
    color: #999999;
  }
}

.LLM {
  margin-top: 80px;

  .text {
    color: #333333;
    font-size: 48px;
    font-family: Source Han Sans CN;
    line-height: 45px;
  }

  .group {
    margin-top: 17px;

    .image_2 {
      border-radius: 1.5px;
      width: 196px;
      height: 3px;
      margin-bottom: 36px;
    }

    .section {
      padding: 42px 20px 0 28px;
      background-color: #e6f1fc;
      border-radius: 20px;
      height: 391px;
      border-left: solid 1px #ebeff6;
      border-right: solid 1px #ebeff6;
      border-top: solid 1px #ebeff6;
      border-bottom: solid 1px #ebeff6;
      width: 480px;

      .text_2 {
        color: #0E59AB;
        line-height: 18px;
      }

      .font_2 {
        font-size: 16px;
        font-family: PingFang SC;
        line-height: 16px;
        color: #333333;
      }

      .text_4 {
        margin-top: 20px;
        color: #666666;
        line-height: 20px;
      }

      .group_5_1 {
        margin-top: 20px;
        padding-left: 4px;
        padding-right: 2px;

        .image_3 {
          width: 20px;
          height: 20px;
        }

        .group_7 {
          margin-top: 3px;
        }

        .image_9 {
          width: 72px;
          height: 78px;
        }

        .group_6 {
          padding-top: 70px;
          width: 160px;

          .image_8 {
            width: 158px;
            height: 171px;
          }

          .image_4 {
            width: 157px;
            height: 147px;
          }

          .pos {
            position: absolute;
            left: 3px;
            right: 0;
            top: 0;
          }
        }
      }
    }

    .section_2 {
      padding: 44px 0 90px 25px;
      border-radius: 20px;
      background-image: url("../../img/b36f9f707422872fe7e68d58cea3fa00.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 271px;
      height: 391px;

      .group_2_1 {
        margin-top: 13px;
        line-height: 28px;
        margin-right: 21px;

        .font_3 {
          font-size: 16px;
          font-family: PingFang SC;
          line-height: 28px;
          color: #ffffff;
        }
      }

      .image_5 {
        margin-top: 40px;
        width: 70px;
        height: 124px;
      }
    }

    .font {
      font-size: 24px;
      font-family: PingFang SC;
      letter-spacing: 2.5px;
      line-height: 24px;
      font-weight: 500;
      color: #ffffff;
    }

    .section_3 {
      padding: 44px 21px 85px 23px;
      border-radius: 20px;
      background-image: url("../../img/b2d1c41e53d06c2e719bd7bb48596939.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 271px;
      height: 391px;

      .group_3 {
        margin-top: 15px;
        line-height: 24px;
      }

      .image_7 {
        margin-right: 30px;
        margin-top: 30px;
        width: 90px;
        height: 121px;
      }
    }

    .section_4 {
      padding: 45px 21px 90px 25px;
      border-radius: 20px;
      background-image: url("../../img/5e855467bdbd55131b932a00f8827c5c.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 271px;
      height: 391px;

      .text_3 {
        line-height: 23px;
      }

      .group_4 {
        margin-top: 15px;
        line-height: 24px;
      }

      .image_6 {
        margin-right: 9px;
        margin-top: 52px;
        width: 130px;
        height: 118px;
      }
    }

    .font_4 {
      font-size: 16px;
      font-family: PingFang SC;
      line-height: 24px;
      color: #ffffff;
    }

    .guidance:hover {
      color: #2a7ee7;
    }

    .section_5 {
      flex: 1 1 346px;

      .image_10 {
        width: 250px;
        height: 68px;
      }

      .section_6 {
        padding: 20px 0 22px;
        background-color: #f0f8ff;
        border-radius: 20px;
        background-image: none;

        .group_8 {
          line-height: 24px;
          text-align: center;
        }
      }

      .section_7 {
        padding: 21px 0;
        background-color: #f0f8ff;
        border-radius: 20px;

        .group_9 {
          line-height: 24px;
          text-align: center;
        }
      }

      .font_5 {
        font-size: 16px;
        font-family: PingFang SC;
        line-height: 24px;
        color: #809dba;
      }

      .text-wrapper {
        padding: 36px 0 37px;
        background-color: #f0f8ff;
        border-radius: 20px;

        .text_5 {
          line-height: 17px;
        }
      }
    }

    .equal-division-item {
      padding: 58px 19px 20px;
      background-color: #e6f1fc;
      border-radius: 20px;
      // height: 270px;
      height: 180px;
      border-left: solid 1px #ebeff6;
      border-right: solid 1px #ebeff6;
      border-top: solid 1px #ebeff6;
      border-bottom: solid 1px #ebeff6;
    }
  }

  .image_11 {
    width: 100vw;
    height: 244.375vw;
  }
}
.newBox {
  z-index: 999;
  // width: 555px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  position: fixed;
  right: 50px;
  top: 90px;
  border-radius: 20px;
  display: flex;
  overflow: hidden;

  .newLeft {
    background-image: url("../../img/30.png");
    background-size: 100% 100%;
    width: 185px;
    height: 200px;
    border-radius: 20px 0px 0px 20px;
    text-align: center;
    .newImg {
      width: 54px;
      height: 54px;
      margin-top: 30px;
    }
    .oneTxt {
      margin: 20px 0 14px 0;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-500;
      font-weight: 500;
      text-align: center;
      color: #333333;
    }
    .twoTxt {
      width: 120px;
      height: 30px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: CENTER;
      color: #666666;
      margin-left: 32px;
    }
  }
  .newRight {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0 15px 15px;
    width: 555px;
    height: 200px;
    overflow-y: auto;
    .newRightOne {
      width: 170px;
      height: 50px;
      background: #eef4ff;
      border-radius: 5px;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
      padding: 8px;
      margin-right: 10px;
    }
    .newRightTwo {
      width: 165px;
      height: 50px;
      border-radius: 5px;
      padding: 8px;
      margin-right: 10px;
      cursor: pointer;
    }
    .newRightTwo:hover {
      background: #eef4ff;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
    }
    .newRightImg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
    .productName {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-500;
      font-weight: 500;
      // text-align: CENTER;
      color: #000000;
    }
    .productTxt {
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: left;
      color: #999999;
      width: 140px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      // margin-top: 5px;
    }
  }
}
.box_7 {
  width: 1443px;
  height: 32px;
  margin: 70px 0 147px 230px;
}
.block_5 {
  height: 200px;
  width: 1920px;
  position: relative;
}

.text-wrapper_4_1 {
  width: 235px;
  height: 45px;
  margin: 80px 0 0 240px;
}
.text_12 {
  width: 235px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  font-family: SourceHanSansCN-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 31px;
}
.box_5 {
  width: 240px;
  height: 3px;
  margin: 17px 0 0 243px;
}

.block_6 {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1px;
  height: 3px;
  width: 240px;
}

.group_13 {
  background-color: rgba(255, 255, 255, 1);
  width: 70px;
  height: 5px;
  margin-top: -2px;
}

.box_6 {
  width: 1403px;
  height: 46px;
  margin: 25px 0 0 243px;
}
.paragraph_11 {
  width: 736px;
  height: 46px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 30px;
}

.image-text_1 {
  width: 78px;
  height: 12px;
  margin-top: 17px;
}

.text-group_1 {
  width: 43px;
  height: 12px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  // line-height: 30px;
}
.image_7_1 {
  width: 24px;
  height: 10px;
  margin-top: 5px;
}
//
/* 定义滚动条的宽度 */
::-webkit-scrollbar {
  width: 5px;
  padding-right: 5px;
}

/* 定义滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background: #f1f2f3;
}

/* 定义滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  background: #9a9b9e;
  border-radius: 10px;
}

/* 当鼠标悬停在滚动条滑块上时，改变其颜色 */
::-webkit-scrollbar-thumb:hover {
  background: #6e7172;
}
</style>

<style scoped lang="scss">
.page_title {
  font-size: 40px;
  font-family: PingFang SC, PingFang SC-500;
  font-weight: 500;
  text-align: LEFT;
  color: #000000;
  line-height: 40px;
  margin: 24px 0 30px 0;
}
.page_2 {
  position: relative;
  .card {
    position: relative;
    left: 240px;
    top: 100px;
    width: 1460px;
  }
  .group_2_item {
    width: 345px;
    height: 240px;
    background: #f6f6f6;
    padding: 20px;
    position: relative;
    margin: 0 20px 20px 0;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #0E59AB;
      .num {
        color: #FFF;
      }
      .p_1 {
        color: #FFF;
      }
      .p_2 {
        color: #FFF;
      }
      .pic_1 {
        display: none;
      }
      .pic_2 {
        display: block;
      }
    }
    img {
      width: 90px;
      height: 90px;
      padding: 10px;
    }
    .pic_1 {
      display: block;
    }
    .pic_2 {
      display: none;
    }
    .num {
      font-size: 60px;
      font-family: PingFang SC, PingFang SC-500;
      font-weight: 500;
      text-align: LEFT;
      color: #333333;
      line-height: 60px;
    }
    .pos {
      margin-top: 45px;
    }
    .p_1 {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-600;
      font-weight: 600;
      text-align: right;
      color: #333333;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .p_2 {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: right;
      color: #666666;
      line-height: 14px;
    }
  }
}

.page_3 {
  width: 1920px;
  height: 960px;
  padding: 100px 240px;
  background-image: url('../../img/image/page_2_bg.png');
  background-size: 100% 100%;
  .title {
    font-size: 80px;
    font-family: Alimama ShuHeiTi, Alimama ShuHeiTi-700;
    font-weight: 700;
    text-align: LEFT;
    color: #ffffff;
    line-height: 80px;
    margin-bottom: 100px;
  }
  .card {

  }
  .grade {
    font-size: 100px;
    font-family: MiSans, MiSans-900;
    font-weight: 900;
    text-align: LEFT;
    color: #ffffff;
    line-height: 100px;
    margin-right: 60px;
  }
  .w_555 {
    width: 555px;
    margin-bottom: 37px;
  }
  .p_1 {
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-500;
    font-weight: 500;
    text-align: LEFT;
    color: #ffffff;
    line-height: 34px;
  }
  .p_2 {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: LEFT;
    color: #ffffff;
    line-height: 27px;
  }
  .pic_1 {
    width: 761px;
    height: 50px;
  }
  .pic_2 {
    width: 324px;
    height: 210px;
    margin-right: 318px;
  }
  .pic_3 {
    width: 741px;
    height: 50px;
  }
  .bot {
    margin-top: 136px;
  }

  /* 初始状态 */
  #page_3_1, #page_3_2 {
    opacity: 1;
    transform: translateX(-1300px); /* 初始向左偏移 */
    transition: transform 2s ease, opacity 2s ease;
  }
  #page_3_2 {
    transform: translateX(1300px); /* 初始向右偏移 */
  }

  /* 触发动画时的状态 */
  .slide-in {
    opacity: 1 !important;
    transform: translateX(0) !important; /* 恢复到原始位置 */
  }

}
.page_4 {
  .picList {
    position: relative;
    left: 240px;
    top: 0px;
    margin-top: 100px;
    .arr1 {
      margin-bottom: 20px;
      width: 290px;
      height: 440px;
      border-radius: 10px;
      transition: all 0.3s;
      margin-right: 20px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .on {
      width: 510px;
    }
    .arr2 {
      width: 345px;
      height: 210px;
      border-radius: 10px;
      margin-right: 20px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        object-fit: cover;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
.page_5 {
  .picList {
    position: relative;
    left: 240px;
    top: 0;
    margin-top: 100px;
    .item {
      width: 215px;
      height: 230px;
      background: #f6f6f6;
      margin-right: 30px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background-color: #0E59AB;
        .p_1 {
          color: #FFF;
        }
        .p_2 {
          color: #FFF;
        }
        .pic_1 {
          display: none;
        }
        .pic_2 {
          display: block;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
      .pic_1 {
          display: block;
        }
        .pic_2 {
          display: none;
        }
      .p_1 {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-600;
        font-weight: 600;
        color: #333333;
        line-height: 24px;
        margin: 20px 0 30px 0;
      }
      .p_2 {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
      }
    }
  }
}
.page_6 {
  margin-top: 100px;
  width: 1920px;
  height: 740px;
  background-image: url('../../img/image/page_6_2.png');
  background-size: 100% 100%;
  .card {
    position: relative;
    left: 240px;
    top: 100px;
    .item {
      width: 460px;
      height: 300px;
      background: rgba(255,255,255,0.50);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-600;
      font-weight: 600;
      text-align: center;
      color: #333333;
      line-height: 34px;
      margin-right: 30px;
      transition: all 0.3s;
      cursor: pointer;
      .icon {
        width: 120px;
        height: 120px;
        background: #ffffff;
        border-radius: 50%;
        padding: 30px;
        margin-bottom: 45px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .p_1 {
        display: block;
      }
      .p_2 {
        display: none;
      }
      .qr {
        display: none;
        height: 100%;
        padding: 30px 0;
        object-fit: cover;
      }
    }
    .hover_1:hover {
      background: #0E59AB;
      color: #fff;
      .p_1 {
        display: none;
      }
      .p_2 {
        display: block;
      }
    }
    .hover_2:hover {
      background: #0E59AB;
      .qr {
        display: block;
      }
      .icon {
        display: none;
      }
      .p_1 {
        display: none;
      }
    }
    .hover_3:hover {
      background: #0E59AB;
      color: #fff;
    }
  }
}

.group_47 {
  margin: 0 !important;
}
.footer {
  width: 1920px;
  height: 360px;
  background: #333333;
  position: relative;
  color: #fff;

  .left {
    position: absolute;
    left: 240px;
    top: 60px;
    .logo {
      width: 300px;
      height: 80px;
    }
    .qr {
      margin-top: 80px;
      margin-right: 30px;
      width: 100px;
      height: 100px;
    }
    .info {
      font-size: 20px;
      font-weight: 500;
      text-align: LEFT;
      color: #ffffff;
      line-height: 20px;
      .item {
        margin-top: 30px;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    
  }
  
  .center {
    position: absolute;
    left: 970px;
    top: 60px;
    .nav {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      color: #ffffff;
      line-height: 16px;
      margin-bottom: 45px;
      .item {
        margin-bottom: 35px;
        cursor: pointer;
        &:hover {
          color: #0e59ab;
        }
      }
    }
    
  }

  .map {
    position: absolute;
    left: 1240px;
    top: 60px;
    width: 440px;
    height: 280px;      
  }

  
}
</style>
